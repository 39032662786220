<script>

export default {
  name: 'layanan',
  data() {
    return {
      services: [],
      rows: 0,
      perPage: 6,
      currentPage: 1,
    }
  },
  watch: {
    currentPage() {
      this.getServices()
    }
  },
  mounted() {
    this.getServices()
  },
  methods: {
    getServices() {
      this.axios.get('service', {
        params: {
          status: 1,
          page: this.currentPage,
          limit: this.perPage,
          sortBy: 'created_at',
          sortType: 'DESC'
        }
      })
        .then( response => {
          this.services = response.data.result.services
          this.rows = response.data.result.total
        })
    }
  }
}
</script>

<template>
  <div>
    <div class="head-for-bg mobile-for-head" :style="{ backgroundImage: `url(${$store.getters.backgroundImage})` }">
      <img :src="$store.getters.backgroundImage" class="hide-mobile" alt="" />
      <div class="for-title pl-res-10 pr-res-10 top-30">
        <span>Layanan</span>
        <p class="text-center">
          Badan Penyelenggara Jaminan Produk Halal (BPJPH) adalah sebuah badan yang terbentuk dibawah naungan Kementerian Agama
        </p>
      </div>
    </div>
    <div class="container">
      <div class="_sub-container">
        <div class="text-left">
          <div class="row">
            <template v-for="(service, i) in services">
              <div class="col-md-4" :key="i">
                <div class="card-for-sec-4">
                  <div class="_content">
                    <img :src="service.icon" alt="ICON" style="width: 40px; height: 40px"/>
                    <h4 class=" ln-2 elipsis-2-char font-weight-6 font-14 pt-18 pb-9">
                      {{ service.title }}
                    </h4>
                    <h4 class="elipsis-3-char ln-c-7    font-weight-5 font-14 color-grey-dark ">
                      {{ service.subtitle }}  
                    </h4>
                    <a :href="`layanan/${service.slug}`" class="trasition-bounce">Selengkapnya</a>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="mt-3 pb-3 no-last style_me"> 
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
        </div>
      </div>
		</div>
  </div>
</template>